<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow+' vertical-divider' : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="12"
          class="space-x-3"
        >
          <!-- <vs-button
            v-on:click="AllocationEngine()"
            color="success"
          >Process</vs-button>
          <vs-button
            v-on:click="cancel()"
            color="success"
          >Cancel</vs-button> -->
          <vs-prompt
            title="Cancel"
            color="danger"
            :active.sync="promptCancel"
            :is-valid="Boolean(cancelObj.notes)"
            @accept="cancel()"
            @close="promptCancel = false"
          >
            <div>
              <vs-input placeholder="Notes" class="w-full" v-model="cancelObj.notes"/>
            </div>
          </vs-prompt>
        </vs-col>
        <div class="vx-row mb-6" style="width: 50%">
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="territory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              label="label"
              track-by="label"
            >
              <!-- <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template> -->
            </multiselect>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerCategory"
              :options="optionCustomerCategory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="deliveryDate"></flat-pickr>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button
              v-on:click="reloadData(params)"
              color="success"
            >Filter</vs-button>
          </div>
        </div>

        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th>
              Action
            </th>
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.salesOrder"
            v-bind:class="[dataId==tr.ID ? 'colored' : '','']">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <!-- <vs-button v-if="dataId==tr.ID "
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button> -->
              <vs-td>
                <feather-icon
                  title="Eye"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="toDetail(data[indextr].ID)"
                />
                <feather-icon
                  title="X"
                  icon="XIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="openCancelPrompt(data[indextr].ID)"
                />
              </vs-td>
              <vs-td>
                {{data[indextr].SalesWorkIdNumber}} ({{ data[indextr].SalesCodeExternal }}) {{ data[indextr].SalesName }}
              </vs-td>
              <vs-td>
                {{data[indextr].CustomerCode}} ({{ data[indextr].CustomerCodeExternal }}) {{ data[indextr].CustomersName }}
              </vs-td>
              <vs-td>
                Code : {{data[indextr].Code}}
                <br/>
                <!-- SO Date : {{ formatDate(data[indextr].PostingDate) }} -->
                SO Date : {{ formatDate(data[indextr].Date) }}
              </vs-td>
              <vs-td>
                {{data[indextr].CustomerAddressCode}}
                <br/>
                {{data[indextr].CustomerAddressContactName}}
                {{data[indextr].CustomerAddressContactMobile}}
                <br/>
                {{data[indextr].CustomerAddressAddress}}, {{data[indextr].CustomerAddressSubDistrict}}, {{data[indextr].CustomerAddressDistrict}}, {{data[indextr].CustomerAddressCity}}, {{data[indextr].CustomerAddressProvince}}, {{data[indextr].CustomerAddressCountry}} {{data[indextr].CustomerAddressPostalCode}}
                {{data[indextr].CustomerAddressLat}}, {{data[indextr].CustomerAddressLong}}
              </vs-td>
              <vs-td>
                Territory : {{ data[indextr].TerritoryCode }} ({{ data[indextr].TerritoryExternalCode2 }}) {{ data[indextr].TerritoryName }}
                <br/>
                Dist. Channel : {{ data[indextr].CustomerCategoryName }}
              </vs-td>
              <vs-td>{{formatCurrency(data[indextr].Total)}}</vs-td>
              <vs-td>{{ data[indextr].Notes }}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>


<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import vSelect from "vue-select";
import moment from 'moment';
export default {
  components: {
    flatPickr,
    vSelect,
  },
  props: {
    // warehouse_id: Number,
    // delivery_plan_id: Number,
    defaultTerritory: Object,
    optionTerritory: Array,
    defaultCustomerCategory: Object,
    optionCustomerCategory: Array,
    defaultDeliveryDate: String,
  },
  data() {
    return {
      promptCancel: false,
      cancelObj: {
        sales_order_id: 0,
        notes: ""
      },
      territory: this.defaultTerritory,
      // optionTerritory: [{ ID: "", label: "" }],
      customerCategory: this.defaultCustomerCategory,
      // optionCustomerCategory: [{ ID: "", Name: "" }],
      configFlatPickr: {
        altFormat: "F j, Y",
        dateFormat: "j F Y",
        enableTime: false,
        // minTime: "00:00",
        plugins: [new ConfirmDatePlugin()],
      },
      deliveryDate: this.defaultDeliveryDate,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code"
      },
      header: [
      {
          text: "Sales ID",
          value: "code"
        },
        {
          text: "Customer Data",
          value: "customer_name"
        },
        {
          text: "SO Data",
          sortable: false
        },
        {
          text: "Delivery Data",
          sortable: false
        },
        {
          text: "Segmetation Data",
          sortable: false
        },
        {
          text: "Order Summary",
          sortable: false
        },
        {
          text: "Note",
          sortable: false
        }
      ],
      responseData: {},
      isActive: 1,
      checked: [],
      modelCheck: [],
      checkedAll: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      option: {
        territory: [],
        salesChannel: [],
        customerCategory: []
      },
      dataId: 0
    };
  },
  computed: {
    // overdue() {
    //   return (due_date) => {
    //     const due = moment(due_date)
    //     const now = moment()
    //     return due.diff(now, "days")
    //   }
    // },
    formatCurrency() {
      return (amount) => {
        const val = Intl.NumberFormat("en-US", {minimumFractionDigits: 2}).format(amount)
        return val
      }
    },
    formatDate() {
      return (date) => {
        return moment(date).format("DD/MM/YYYY")
      }
    }
  },
  // mounted() {
  //   this.$http.get("/api/v1/sales-order-processing/list/territory").then(resp => {
  //     if (resp.code == 200) {
  //       // this.optionTerritory = resp.data.territory;
  //       this.optionTerritory = resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`}));
  //     } else {
  //       // this.$vs.loading.close();
  //       this.$router.push("/");
  //     }
  //   });
  //   this.$http.get("/api/v1/master/customer-category-all").then(resp => {
  //     if (resp.code == 200) {
  //       this.optionCustomerCategory = resp.data;
  //     } else {
  //       this.$router.push("/");
  //     }
  //   });
  // },
  methods: {
    toDetail(soID) {
      this.$emit("toDetail", {sales_order_id: soID, customerCategory: this.customerCategory, territory: this.territory, deliveryDate: this.deliveryDate})
    },
    openCancelPrompt(soID) {
      this.promptCancel = true
      this.cancelObj.sales_order_id = soID
    },
    cancel() {
      this.$vs.loading();
      this.$http
        .post("api/v1/sales-order-processing/so-view/cancel", this.cancelObj)
        .then(resp => {
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.reloadData(this.params);
            this.promptCancel = false
            this.cancelObj.sales_order_id = 0
            this.cancelObj.notes = ""
            this.$vs.loading.close();
          }
          this.checked.splice(0, this.checked.length)
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
        });
    },
    clickTr(val) {
      this.closeDetail();
      setTimeout(function() {}, 3000);
      this.dataId = val.ID;
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    addChecked(val) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].ID)) {
            //
          } else {
            this.checked.push(this.data[i].ID);
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.data[j].ID), 1);
        }
      }
    },
    reloadData(params) {
      // if (this.territory.ID && this.customerCategory.ID && this.deliveryDate) {
        this.params = params;
        this.$vs.loading();
        this.$http
          .get("api/v1/sales-order-processing/list/sales-order", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              territory_id: this.territory.ID || "",
              // sales_channel_id: this.salesChannel.id || "",
              customer_category_id: this.customerCategory.ID || "",
              start_date: this.startDate,
              end_date: this.endDate,
              warehouse_id: this.warehouse_id || "",
              due_date: this.dueDate,
              delivery_plan_id: this.delivery_plan_id,
              // delivery_date: this.deliveryDate,
              activeTab: "soView",
              // start_date: this.startDate,
              // end_date: this.endDate
            }
          })
          .then(resp => {
            if (resp.code == 500) {
              this.$vs.loading.close();
              this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            } else if (resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.salesOrder;
              this.responseData = resp.data;
              this.checkedAll = false;
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            }
          });
      // }
    }
  }
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 30px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
