<template>
  <vx-card>
    <vs-tabs :color="colorx" v-model="tabs">
      <vs-tab ref="so_view" label="SKU">
        <div class="con-tab-ejemplo">
          <so-view
            @toEdit="toEdit"
            :warehouse_id="wh_id"
            :delivery_plan_id="dp_id"
            :defaultTerritory="territory"
            :optionTerritory="optionTerritory"
            :defaultCustomerCategory="customerCategory"
            :optionCustomerCategory="optionCustomerCategory"
            :defaultDeliveryDate="deliveryDate"
          ></so-view>
        </div>
      </vs-tab>
      <vs-tab @click:colorx="colorx = 'warning'" label="Edit">
        <div v-if="disabled_edit"></div>
        <div v-else class="con-tab-ejemplo">
          <edit
            @back="() => tabs = 0"
            :sku_code="sku_code"
            :defaultTerritory="territory"
            :defaultCustomerCategory="customerCategory"
            :warehouse_id="wh_id"
            :deliveryDate="deliveryDate"
          ></edit>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import soView from "./view.vue";
import edit from "./edit.vue";
import moment from "moment";

export default {
  components: {
    soView,
    edit,
  },
  data() {
    return {
      colorx: "danger",
      items: [],
      tabs: 0,
      disabled_view: true,
      disabled_edit: true,
      wh_id: null,
      dp_id: null,
      sku_code: null,
      territory: {ID: 0, label: "All"},
      customerCategory: {ID: 0, name: "All"},
      optionTerritory: [],
      optionCustomerCategory: [],
      deliveryDate: moment().format("D MMMM yyyy"),
    };
  },
  watch: {
    // tabs(d) {
    //   // if (d == 0) {
    //   //   this.disabled_view = true
    //   //   this.disabled_edit = true
    //   // } else if (d == 1) {
    //   //   this.disabled_edit = true
    //   // }
    //   console.log(d)
    //   console.log(this.territory)
    //   console.log(this.customerCategory)
    //   console.log(this.deliveryDate)
    // }
  },
  methods: {
    toView(data) {
      this.disabled_view = false
      this.tabs = 1
      this.wh_id = data.warehouse_id
      this.dp_id = data.delivery_plan_id
      console.log("---------------->", this.dp_id)
      this.territory = data.territory
      this.customerCategory = data.customerCategory
      this.deliveryDate = data.deliveryDate
    },
    toEdit(data) {
      this.disabled_edit = false
      // this.tabs = 2
      this.tabs = 1
      this.sku_code = data.sku_code
      this.wh_id = data.warehouse_id
      this.territory = data.territory
      this.customerCategory = data.customerCategory
      this.deliveryDate = data.deliveryDate
      console.log(this.territory)
      console.log(this.customerCategory)
    },
    clickView() {
      if (this.disabled_view) {
        this.tabs = 0
      } else {
        this.colorx = 'danger'
      }
    }
  },
  mounted() {
    this.$vs.loading()
    this.$http.get("/api/v1/sales-order-processing/list/territory").then(resp => {
      if (resp.code == 200) {
        // this.optionTerritory = resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`, name: t.name}));
        this.optionTerritory = [{ID: 0, label: "All"}, ...resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`}))];
      } else {
        // this.$vs.loading.close();
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
    this.$http.get("/api/v1/master/customer-category-all").then(resp => {
      if (resp.code == 200) {
        // this.optionCustomerCategory = resp.data;
        this.optionCustomerCategory = [{ID: 0, name: "All"}, ...resp.data];
      } else {
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
    // this.deliveryDate = new Date().toString()
  }
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
.divider-bottom {
  border-bottom: 1px solid #6c6cec;
  /* min-height:800px; */
  padding: 5px;
}
</style>