<template>
  <div >
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <div class="flex justify-end">
          <!-- <div> -->
            <vs-button @click="() => $emit('back')">Back</vs-button>
          <!-- </div> -->
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full items-center">
            <vs-input :value="territory" disabled></vs-input>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input :value="distribution_channel" disabled></vs-input>
          </div>
        </div>
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
     <template slot="tbody">
       <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.soSku">
          <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
          <vs-td>{{ indextr == 0 ? `${tr.CustomerCode} (${tr.CustomerExternalCode}) ${tr.CustomerName}` : ""}}</vs-td>
          <vs-td>{{ indextr == 0 ? `Code :${tr.SalesOrderCode} SO Date :${formatDate(tr.SalesOrderPostingDate)}` : ""}}</vs-td>
          <vs-td>{{ tr.ItemCode }} {{ tr.ItemName }}</vs-td>
          <vs-td>{{ tr.QuantityGeneral }}</vs-td>
          <vs-td>{{ tr.Adjustment }}</vs-td>
          <vs-td>{{ tr.Adjustment }}</vs-td>
          <vs-td>{{ formatEquivalent(tr.Adjustment) }}</vs-td>
          <!-- <vs-td>{{ tr.AllocationStatus == 4 ? "None" : tr.QuantityOriginal > tr.Adjustment ? "Partial" : "Full" }}</vs-td> -->
          <vs-td>{{ tr.AllocationStatus.split(",").includes("3") ? tr.QuantityGeneral > tr.Adjustment ? "Partial" : "Full" : "None" }}</vs-td>
        </vs-tr>
     </template>
     </data-table>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  components: {
  },
  props: {
    salesOrderID: Number,
    defaultTerritory: Object,
    defaultCustomerCategory: Object,
    // warehouse_id: Number,
    // deliveryDate: String,
  },
  data() {
    return {
      territory: this.defaultTerritory.label,
      distribution_channel: this.defaultCustomerCategory.name,
      stock: 0,
      suggestion: 0,
      adjustment: 0,
      delta: 0,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "item_name"
      },
      header: [
        {
          text: "Customer Data",
        },
        {
          text: "SO Data",
        },
        {
          text: "SKU",
        },
        {
          text: "Order Qty [UOM]",
        },
        {
          text: "Suggestion Qty [UOM]",
        },
        {
          text: "Adjustment Qty [UOM]",
        },
        {
          text: "Info Adjusted HU Equivalent",
        },
        {
          text: "Status"
        },
      ],
      responseData: {}
    }
  },
  watch: {
    // "responseData": {
    //   handler: function (val) {
    //     this.adjustment = val.data.reduce((total, current) => total + parseInt(current.Adjustment || "0"), 0)
    //   },
    //   deep: true,
    // },
    // adjustment: function (val) {
    //   this.delta = parseInt(this.stock) - parseInt(val)
    // }
  },
  computed: {
    formatEquivalent: () => {
      return (val) => {
        const karton = Math.floor(val / 144)
        const sisaKarton = val % 144
        const lusin = Math.floor((sisaKarton) / 12)
        const pcs = sisaKarton % 12 
        return `${karton}.${lusin}.${pcs}`
      }
    },
    formatDate: () => {
      return (val) => moment.utc(val).format("YYYY-MM-DD")
    },
  },
  methods: {
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/sales-order-processing/list/so-view", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            sales_order_id: this.salesOrderID
          }
        })
        .then(
          resp => {
            if(resp.code == 200){
              this.responseData = resp.data;
              // this.stock = resp.data.stock
              // this.suggestion = resp.data.adjustment
              // this.adjustment = resp.data.adjustment
            } else {
              this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            }
            this.$vs.loading.close();
          }
        );
    },
  }
};
</script>