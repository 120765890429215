<template>
  <div >
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <div class="flex justify-end">
          <!-- <div> -->
            <vs-button @click="() => $emit('back')">Back</vs-button>
          <!-- </div> -->
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full items-center">
            <vs-input :value="territory" disabled></vs-input>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input :value="distribution_channel" disabled></vs-input>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row space-x-3">
      <div
        class="sm:w-1/6 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>Control Qty</span>
      </div>
      <div
        class="sm:w-1/6 w-full flex flex-col items-center"
        style="margin-bottom: 30px"
      >
        <div class="">Stock</div>
        <div class="">
          <vs-input class="w-full" v-model="stock" disabled></vs-input>
        </div>
      </div>
      <div
        class="sm:w-1/6 w-full flex flex-col items-center"
        style="margin-bottom: 30px"
      >
        <div class="">Suggestion</div>
        <div class="">
          <vs-input class="w-full" v-model="suggestion" disabled></vs-input>
        </div>
      </div>
      <div
        class="sm:w-1/6 w-full flex flex-col items-center"
        style="margin-bottom: 30px"
      >
        <div class="">Adjustment</div>
        <div class="">
          <vs-input class="w-full" v-model="adjustment" disabled></vs-input>
        </div>
      </div>
      <div
        class="sm:w-1/6 w-full flex flex-col items-center"
        style="margin-bottom: 30px"
      >
        <div class="">Delta</div>
        <div class="">
          <vs-input class="w-full" v-model="delta" disabled></vs-input>
        </div>
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
     <template slot="tbody">
       <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.data">
          <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
          <vs-td>{{ indextr == 0 ? `${tr.ItemCode} ${tr.ItemName}` : ""}}</vs-td>
          <vs-td>{{tr.CustomerCode}} ({{tr.CustomerExternalCode}}) {{ tr.CustomerName }}</vs-td>
          <vs-td> Code :{{tr.SalesOrderCode}} <br/> SO Date :{{formatDate(tr.SalesOrderPostingDate)}} {{ tr.CustomerName }}</vs-td>
          <vs-td>{{ tr.QuantityGeneral }}</vs-td>
          <vs-td>{{ tr.Suggestion }}</vs-td>
          <vs-td><vs-input v-model="tr.Adjustment"></vs-input></vs-td>
          <vs-td>{{ formatEquivalent(tr.Adjustment) }}</vs-td>
        </vs-tr>
     </template>
     </data-table>
     <div>
      <vs-button @click="save">Save</vs-button>
     </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  components: {
  },
  props: {
    sku_code: String,
    defaultTerritory: Object,
    defaultCustomerCategory: Object,
    warehouse_id: Number,
    deliveryDate: String,
  },
  // mounted() {
  //   this.reloadData(this.params)
  // },
  data() {
    return {
      territory: this.defaultTerritory.label,
      distribution_channel: this.defaultCustomerCategory.name,
      stock: 0,
      suggestion: 0,
      adjustment: 0,
      delta: 0,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "item_name"
      },
      header: [
        {
          text: "SKU Data",
          // value:'sku'
          // width: '5%'
        },
        {
          text: "Customer Data",
          // value: "name"
        },
        {
          text: "SO Data",
          // value: "name"
        },
        {
          text: "Order Qty [UOM]",
          // value: "name"
        },
        {
          text: "Suggestion Qty [UOM]",
          // value: "name"
        },
        {
          text: "Adjustment Qty [UOM]",
          // value: "name"
        },
        {
          text: "Info Adjusted HU Equivalent",
          // value: "name"
        },
      ],
      responseData: {}
    }
  },
  watch: {
    "responseData": {
      handler: function (val) {
        this.adjustment = val.data.reduce((total, current) => total + parseInt(current.Adjustment || "0"), 0)
      },
      deep: true,
    },
    adjustment: function (val) {
      this.delta = parseInt(this.stock) - parseInt(val)
    }
  },
  computed: {
    formatEquivalent: () => {
      return (val) => {
        const karton = Math.floor(val / 144)
        const sisaKarton = val % 144
        const lusin = Math.floor((sisaKarton) / 12)
        const pcs = sisaKarton % 12 
        return `${karton}.${lusin}.${pcs}`
      }
    },
    formatDate: () => {
      return (val) => moment.utc(val).format("YYYY-MM-DD")
    },
  },
  methods: {
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/sales-order-processing/list/edit", {
          params: {
            // sales_order_id: this.dataId,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            // sku_code: this.$route.params.sku_code,
            territory_id: this.defaultTerritory.ID,
            customer_category_id: this.defaultCustomerCategory.ID,
            item_code: this.sku_code,
            warehouse_id: this.warehouse_id,
            delivery_date: this.deliveryDate,
          }
        })
        .then(
          resp => {
            if(resp.code == 200){
              this.responseData = resp.data;
              this.responseData.data = resp.data.data.map(r => ({...r, Suggestion: r.Adjustment}));
              this.stock = resp.data.stock
              this.suggestion = resp.data.adjustment
              this.adjustment = resp.data.adjustment
            } else {
              this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            }
            this.$vs.loading.close();
          }
        );
    },
    save() {
      if (this.delta < 0) {
      // if (this.delta < -20) {
        this.$vs.notify({
          title: "Danger",
          text: "Delta cannot be negative",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x",
        });
        return
      }

      this.$vs.loading()
      const itemCode = this.responseData.data[0].ItemCode
      const warehouseID = this.responseData.data[0].WarehouseID
      let data = this.responseData.data.map(d => ({
        so_id: d.SOID,
        // sol_id: d.SOLID,
        // soal_id: d.SOALID,
        item_code: d.ItemCode,
        adjustment: parseInt(d.Adjustment || "0"),
        suggestion: parseInt(d.Suggestion || "0"),
      }))
      this.$http
        .post("api/v1/sales-order-processing/list/edit", {
          item_code: itemCode,
          warehouse_id: warehouseID,
          sales_order_adjust_line: data,
        })
        .then(
          resp => {
            if (resp.code == 500) {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x",
              });
            } else if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              this.$emit('back')
              // this.reloadData(this.params);
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x",
              });
            }
          }
        );
    },
  }
};
</script>