<template>
  <vx-card title="SO Processing">
    <vs-tabs :color="colorx" v-model="tabs">
      <vs-tab @click="colorx = 'danger'" label="Open">
        <div class="con-tab-ejemplo">
          <open v-if="renderComponent"></open>
        </div>
      </vs-tab>
      <vs-tab @click="colorx = 'danger'" label="Manual">
        <div class="con-tab-ejemplo">
          <manual v-if="renderComponent"></manual>
        </div>
      </vs-tab>
      <!-- <vs-tab @click="colorx = 'danger'" label="SO View">
        <div class="con-tab-ejemplo">
          <so-view v-if="renderComponent"></so-view>
        </div>
      </vs-tab> -->
      <vs-tab @click:colorx="colorx = 'warning'" label="Processed SO">
        <div class="con-tab-ejemplo">
          <draft v-if="renderComponent"></draft>
        </div>
      </vs-tab>
      <vs-tab @click="colorx = 'success'" label="Released DP">
        <div class="con-tab-ejemplo">
          <released v-if="renderComponent"></released>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import open from "./open/open.vue";
// import manual from "./manual/manual.vue";
import manual from "./manual/manual2.vue";
// import soView from "./so_view/soView.vue";
import soView from "./so_view/so_view.vue";
// import draft from "./draft/draft.vue";
import draft from "./draft/draft_2.vue";
// import released from "./released/released.vue";
import released from "./released/released_2.vue";

export default {
    components: {
        open,
        manual,
        soView,
        draft,
        released,
    },
    data() {
        return {
            renderComponent: true,
            colorx: "danger",
            items: [],
            tabs: 0,
        };
    },
    methods: {
        forceRerender() {
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        changeTabs(index, indexTab) {
            this.numberTabs[index] = indexTab;
        },
    },
};
</script>

<style scoped>
.vs-collapse-item--content {
    height: 100%;
    max-height: 14444px;
}
.divider-bottom {
    border-bottom: 1px solid #6c6cec;
    /* min-height:800px; */
    padding: 5px;
}
</style>