<template>
  <vx-card>
    <vs-tabs :color="colorx" v-model="tabs">
      <vs-tab ref="so_view" label="Sales Order">
        <div class="con-tab-ejemplo">
          <sales-order
            @toDetail="toDetail"
            :warehouse_id="wh_id"
            :delivery_plan_id="dp_id"
            :defaultTerritory="territory"
            :optionTerritory="optionTerritory"
            :defaultCustomerCategory="customerCategory"
            :optionCustomerCategory="optionCustomerCategory"
            :defaultDeliveryDate="deliveryDate"
          ></sales-order>
        </div>
      </vs-tab>
      <vs-tab @click:colorx="colorx = 'warning'" label="Detail">
        <div v-if="disabled_detail"></div>
        <div v-else class="con-tab-ejemplo">
          <detail
            @back="() => tabs = 0"
            :salesOrderID="sales_order_id"
            :defaultTerritory="territory"
            :defaultCustomerCategory="customerCategory"
          ></detail>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import salesOrder from "./salesOrder.vue";
import detail from "./detail.vue";
import moment from "moment"

export default {
  components: {
    salesOrder,
    detail,
  },
  data() {
    return {
      colorx: "danger",
      items: [],
      tabs: 0,
      disabled_view: true,
      disabled_detail: true,
      wh_id: null,
      dp_id: null,
      sales_order_id: null,
      territory: {ID: 0, label: "All"},
      customerCategory: {ID: 0, name: "All"},
      optionTerritory: [],
      optionCustomerCategory: [],
      deliveryDate: moment().format("D MMMM yyyy"),
    };
  },
  // watch: {
  //   tabs(d) {
  //     console.log(d)
  //     console.log(this.territory)
  //     console.log(this.customerCategory)
  //     console.log(this.deliveryDate)
  //   }
  // },
  methods: {
    toView(data) {
      this.disabled_view = false
      this.tabs = 1
      this.wh_id = data.warehouse_id
      this.dp_id = data.delivery_plan_id
      this.territory = data.territory
      this.customerCategory = data.customerCategory
      this.deliveryDate = data.deliveryDate
    },
    toDetail(data) {
      this.disabled_detail = false
      // this.tabs = 2
      this.tabs = 1
      this.sales_order_id = data.sales_order_id
      this.territory = data.territory
      this.customerCategory = data.customerCategory
      this.deliveryDate = data.deliveryDate
    },
    // changeTerritory(data) {
    //   console.log(data)
    //   this.territory = data
    // },
    // changeCustomerCategory(data) {
    //   console.log(data)
    //   this.customerCategory = data
    // },
    // changeDeliveryDate(data) {
    //   console.log(data)
    //   this.deliveryDate = data
    // },
    clickView() {
      if (this.disabled_view) {
        this.tabs = 0
      } else {
        this.colorx = 'danger'
      }
    }
  },
  mounted() {
    this.$vs.loading()
    this.$http.get("/api/v1/sales-order-processing/list/territory").then(resp => {
      if (resp.code == 200) {
        // this.optionTerritory = resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`, name: t.name}));
        this.optionTerritory = [{ID: 0, label: "All"}, ...resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`}))];
      } else {
        // this.$vs.loading.close();
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
    this.$http.get("/api/v1/master/customer-category-all").then(resp => {
      if (resp.code == 200) {
        // this.optionCustomerCategory = resp.data;
        this.optionCustomerCategory = [{ID: 0, name: "All"}, ...resp.data];
      } else {
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
    // this.deliveryDate = new Date().toString()
  },
  // watch: {
  //   "salesChannel.selected": function (v) {
  //     this.salesChannelId = v.id;
  //   },
  // },
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
.divider-bottom {
  border-bottom: 1px solid #6c6cec;
  /* min-height:800px; */
  padding: 5px;
}
</style>