<template>
  <div>
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="12"
          class="justify-between"
        >
          <div class="space-x-3">
            <vs-button
              v-on:click="openProcessDialog()"
              color="success"
            >Process</vs-button>
            <vs-button
              v-on:click="openCancelDialog()"
              color="success"
            >Cancel All</vs-button>
            <vs-prompt
              title="Cancel"
              color="danger"
              :active.sync="promptCancel"
              :is-valid="Boolean(notes)"
              @accept="cancelAll()"
              @close="promptCancel = false"
            >
              <div>
                <vs-input placeholder="Notes" class="w-full" v-model="notes"/>
              </div>
            </vs-prompt>
          </div>
          <!-- <div>
            <vs-button @click="() => $emit('back')">Back</vs-button>
          </div> -->
        </vs-col>
        <div class="vx-row mb-6" style="width: 50%">
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="territory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              label="label"
              track-by="label"
            ></multiselect>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerCategory"
              :options="optionCustomerCategory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              label="name"
              track-by="name"
            ></multiselect>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
            <span>Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="deliveryDate"></flat-pickr>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full flex items-center"
            style="margin-bottom: 30px"
          >
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button
              v-on:click="reloadData(params)"
              color="success"
            >Filter</vs-button>
          </div>
        </div>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th>Action</th>
          </template>
          <template slot="tbody">
            <!-- <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.soSku" -->
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <!-- <vs-button v-if="dataId==tr.ID "
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button> -->
              <vs-td>
                <!-- <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="$router.push(`/delivery_plan/edit/${data[indextr].ItemCode}`)"
                /> -->
                <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="toEdit(data[indextr].ItemCode, data[indextr].WarehouseID)"
                />
              </vs-td>
              <vs-td>{{ data[indextr].ItemCode }} {{ data[indextr].ItemName }}</vs-td>
              <vs-td>{{ data[indextr].OfSO }}</vs-td>
              <vs-td>{{data[indextr].TotalOrder}}</vs-td>
              <vs-td>{{data[indextr].TotalStock}}</vs-td>
              <vs-td>{{data[indextr].Insufficient}}</vs-td>
              <vs-td>{{data[indextr].Adjustment}}</vs-td>
              <vs-td>{{data[indextr].Adjustment}}</vs-td>
              <vs-td>{{data[indextr].Control}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>


<script>
import edit from "./edit.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import vSelect from "vue-select";
export default {
  components: {
    edit,
    flatPickr,
    vSelect,
  },
  props: {
    warehouse_id: Number,
    delivery_plan_id: Number,
    defaultTerritory: Object,
    optionTerritory: Array,
    defaultCustomerCategory: Object,
    optionCustomerCategory: Array,
    defaultDeliveryDate: String,
  },
  data() {
    return {
      territory: this.defaultTerritory,
      // optionTerritory: [{ ID: "", label: "" }],
      customerCategory: this.defaultCustomerCategory,
      // optionCustomerCategory: [{ ID: "", name: "" }],
      item_code: "",
      configFlatPickr: {
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "F j, Y",
        // altInput: true,
        dateFormat: "j F Y",
        enableTime: false,
        plugins: [new ConfirmDatePlugin()],
      },
      activeTab: "manual",
      promptCancel: false,
      notes: "",
      deliveryDate: this.defaultDeliveryDate,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "item_code"
      },
      header: [
        {
          text: "SKU Code / Description",
          value: "sku_code"
        },
        {
          text: "# of SO",
          value: "of_so"
        },
        {
          text: "Total Order Qty [UOM]",
          value: "total_so",
          sortable: true,
        },
        {
          text: "Total Stock Qty [UOM]",
          value: "total_stock",
          sortable: true
        },
        {
          text: "Insufficient Stock [UOM]",
          value: "insufficient",
          sortable: false
        },
        {
          text: "Suggestion [UOM]",
          value: "suggestion",
          sortable: false
        },
        {
          text: "Adjustment [UOM]",
          value: "adjustment",
          sortable: false
        },
        {
          text: "Control [UOM]",
          value: "constrol",
          sortable: false
        }
      ],
      responseData: {},
      isActive: 1,
      checked: [],
      modelCheck: [],
      checkedAll: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      // detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      option: {
        territory: [],
        salesChannel: [],
        customerCategory: []
      },
      dataId: 0,
      data: [],
    };
  },
  mounted() {
    // this.$http.get("/api/v1/sales-order-processing/list/territory").then(resp => {
    //   if (resp.code == 200) {
    //     this.optionTerritory = resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`, name: t.name}));
    //   } else {
    //     // this.$vs.loading.close();
    //     this.$router.push("/");
    //   }
    // });
    // this.$http.get("/api/v1/master/customer-category-all").then(resp => {
    //   if (resp.code == 200) {
    //     this.optionCustomerCategory = resp.data;
    //   } else {
    //     this.$router.push("/");
    //   }
    // });
  },
  methods: {
    openProcessDialog() {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Confirm`,
        text: "Are you sure to process",
        acceptText: "Yes",
        cancelText: "No",
        accept:() => {
          this.process();
        },
      });
    },
    process() {
      this.$vs.loading();
      this.$http
        .post("api/v1/sales-order-processing/list/so-sku-view/process", {
          search: this.params.search,
              territory_id: this.territory.ID,
              customer_category_id: this.customerCategory.ID,
              warehouse_id: this.warehouse_id,
              delivery_plan_id: this.delivery_plan_id,
              delivery_date: this.deliveryDate,
        })
        .then(resp => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.reloadData(this.params);
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          }
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
        });
    },
    openCancelDialog() {
      this.promptCancel = true
    },
    cancelAll() {
      this.$vs.loading();
      this.$http
        .post("api/v1/sales-order-processing/list/so-sku-view/cancel", {
          notes: this.notes,
          territory_id: this.territory.ID || "",
          customer_category_id: this.customerCategory.ID || "",
          warehouse_id: this.warehouse_id,
          delivery_plan_id: this.delivery_plan_id,
          delivery_date: this.deliveryDate,
        })
        .then(resp => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.notes = ""
            this.reloadData(this.params);
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          }
          // this.checked.splice(0, this.checked.length)
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
        });
    },
    clickTr(val) {
      this.closeDetail();
      setTimeout(function() {}, 3000);
      this.dataId = val.ID;
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    addChecked(val) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].ID)) {
            //
          } else {
            this.checked.push(this.data[i].ID);
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.data[j].ID), 1);
        }
      }
    },
    reloadData(params) {
      // if (this.territory.ID && this.customerCategory.ID && this.deliveryDate) {
      //   this.params = params;
      //   this.$vs.loading();
      //   this.$http
      //     .get("api/v1/sales-order-processing/list/so-sku-view", {
      //       params: {
      //         search: params.search,
      //         length: params.length,
      //         page: params.page,
      //         order: params.order,
      //         sort: params.sort,
      //         territory_id: this.territory.ID || "",
      //         // sales_channel_id: this.salesChannel.id || "",
      //         customer_category_id: this.customerCategory.ID || "",
      //         // warehouse_id: this.$route.params.wh_id,
      //         warehouse_id: this.warehouse_id,
      //         delivery_plan_id: this.delivery_plan_id,
      //         delivery_date: this.deliveryDate,
      //         activeTab: this.activeTab,
      //         // start_date: this.startDate,
      //         // end_date: this.endDate
      //       }
      //     })
      //     .then(resp => {
      //       if (resp.code == 500) {
      //         this.$vs.loading.close();
      //       } else if (resp.code == 200) {
      //         this.$vs.loading.close();
      //         this.data = resp.data.soSku;
      //         this.responseData = resp.data;
      //         this.checkedAll = false;
      //       } else {
      //         this.$vs.loading.close();
      //       }
      //     });
      // }
      // if (this.territory.ID && this.customerCategory.ID && this.deliveryDate) {
        this.params = params;
        this.$vs.loading();
        this.$http
          .get("api/v1/sales-order-processing/list/sales-order", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              territory_id: this.territory.ID || "",
              // sales_channel_id: this.salesChannel.id || "",
              customer_category_id: this.customerCategory.ID || "",
              // start_date: this.startDate,
              // end_date: this.endDate,
              delivery_date: this.deliveryDate,
              activeTab: "manual"
            }
          })
          .then(resp => {
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else if (resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.salesOrder;
              this.responseData = resp.data;
              this.checkedAll = false;
            } else {
              this.$vs.loading.close();
            }
          });
      // }
    },
    toEdit(sku_code, warehouse_id) {
      this.$emit("toEdit", {sku_code, warehouse_id, customerCategory: this.customerCategory, territory: this.territory, deliveryDate: this.deliveryDate})
    },
  }
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 30px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>