<template>
  <vx-card>
    <vs-tabs :color="colorx" v-model="tabs">
      <vs-tab ref="so_view" label="SO View">
        <div class="con-tab-ejemplo">
          <so-view></so-view>
        </div>
      </vs-tab>
      <vs-tab @click:colorx="colorx = 'warning'" label="SKU View">
        <div class="con-tab-ejemplo">
          <sku-view></sku-view>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import skuView from "./skuView/skuView.vue";
import soView from "./soView/so_view.vue";
import moment from "moment"

export default {
  components: {
    soView,
    skuView,
  },
  data() {
    return {
      colorx: "danger",
      items: [],
      tabs: 0,
      disabled_view: true,
      disabled_edit: true,
      wh_id: null,
      dp_id: null,
      sku_code: null,
      territory: {},
      customerCategory: {},
      optionTerritory: [],
      optionCustomerCategory: [],
      deliveryDate: moment().format("D MMMM yyyy"),
    };
  },
  watch: {
    tabs(d) {
      // if (d == 0) {
      //   this.disabled_view = true
      //   this.disabled_edit = true
      // } else if (d == 1) {
      //   this.disabled_edit = true
      // }
      console.log(d)
      console.log(this.territory)
      console.log(this.customerCategory)
      console.log(this.deliveryDate)
    }
  },
  methods: {
    
  },
  mounted() {
    this.$vs.loading()
    this.$http.get("/api/v1/sales-order-processing/list/territory").then(resp => {
      if (resp.code == 200) {
        this.optionTerritory = resp.data.territory.map((t) => ({ID: t.ID, label: `${t.code} ${t.name}`, name: t.name}));
      } else {
        // this.$vs.loading.close();
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
    this.$http.get("/api/v1/master/customer-category-all").then(resp => {
      if (resp.code == 200) {
        this.optionCustomerCategory = resp.data;
      } else {
        this.$router.push("/");
      }
      this.$vs.loading.close()
    });
  },
  // watch: {
  //   "salesChannel.selected": function (v) {
  //     this.salesChannelId = v.id;
  //   },
  // },
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
.divider-bottom {
  border-bottom: 1px solid #6c6cec;
  /* min-height:800px; */
  padding: 5px;
}
</style>